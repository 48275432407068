import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion ,jumpToHome, setNavColor } from '../../common/js/navigateTools';
// import { prohibitedProductService } from '../../axios/service';
import Button from '../../components/button';
import './jointLogonFail.less';
import jointFailIcon from "../../common/images/status/jointFailIcon.png";
const JointLogonFail = (props) => {
    useEffect(() => {
        setTitle("Unable To Apply");
        setNavExpansion(0); // 是否隐藏导航栏
        setNavColor("#FDDD00", "#FFFFFF");
        // this.getProhibitedProduct();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    // 获取小黑屋展示信息
    /*async getProhibitedProduct = () => {
        let data = await prohibitedProductService();
        console.log(data);
    }*/
    // 去首页
    const goIndex = () => {
        jumpToHome();
    }
    return (
        <div className="P-jointLogonFail">
            <div className='P-jointLogonFail_content'>
                <img src={jointFailIcon} className='jointFailIcon' />
                {/* tips */}
                <div className='P-jointLogonFail_tips'>
                    <p className='tips_status'>WE SINCERELY APOLOGIZE FOR NOTIFYING YOU...</p>
                    <p className='tips_txt'>After Careful Review, We Regret To Inform You That We Are Unable To Approve Your Loan Application</p>
                </div>
            </div>
            <div className='P-jointLogonFail_btnArea'>
                <Button
                content="Home"
                clickFunc={() => goIndex()} />
            </div>
        </div>
    );
}

export default JointLogonFail;

