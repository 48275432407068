import React, { Fragment, useState, useEffect } from "react";
import { 
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import LottieLoader from "./components/loading";
import Official from "./pages/official";
import LoanAgreement from "./pages/agreement/loanAgreement"; // 借款协议（分期）
import PrivacyAgreement from "./pages/agreement/privacyAgreement"; // 隐私协议
import FraudBanner from "./pages/banner/fraud/fraud"; // 放诈指南
import CustomerService from "./pages/customerService/customerService"; // 客服中心
import JointLogonFail from "./pages/jointLogonFail/jointLogonFail"; // 联登失败页
import ConfirmOfLoan from "./pages/confirmOfLoan/confirmOfLoan"; // 确认用款页
import LoanDetails from "./pages/loanDetails/loanDetails"; // 订单详情页
import RepaymentOfPeriod from "./pages/repay/repayment/repaymentOfPeriod"; // 还款计划详情页
import RepaymentOfDelay from "./pages/repay/repayment/repaymentOfDelay"; // 展期详情页
import RepaymentOfCardPage from "./pages/repay/repaymentOfCard/repaymentOfCard"; // 还款方式页
import RepaymentOfCode from "./pages/repay/repaymentOfCode/repaymentOfCode"; // 还款详情页 - 还款码
import BindCardList from "./pages/bindCard/bindCardList"; // 银行卡列表页
import BindCard from "./pages/bindCard/bindCard"; // 绑定新卡页

function App(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 模拟一个异步操作，例如数据加载
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Fragment>
      {loading && <LottieLoader />}
      <Router>
          <div className="App">
            <Routes>
                <Route path="/" element={<Official />} />
                <Route path="/ptka-sea" element={<LoanAgreement />} />
                <Route path="/ptka-last" element={<PrivacyAgreement />} />
                <Route path="/privacyAgreement" element={<PrivacyAgreement />} />
                {/* 诈骗 */}
                <Route path="/fraud" element={<FraudBanner />} />
                {/* 客服中心 */}
                <Route path="/ptka-shark" element={<CustomerService />} />
                {/* 联登失败页 - 小黑屋 */}
                <Route path="/ptka-used" element={<JointLogonFail />} />
                {/* 确认用款页 */}
                <Route path="/ptka-one" element={<ConfirmOfLoan />} />
                {/* 订单详情页 */}
                <Route path="/ptka-wto" element={<LoanDetails />} />
                {/* 单期还款页 */}
                {/* <Route path="/couldseeAskedjack" element={<RepaymentOfPeriod />} /> */}
                {/* 展期还款页 */}
                <Route path="/ptka-lony" element={<RepaymentOfDelay />} />
                {/* 还款方式页面 */}
                <Route path="/ptka-func" element={<RepaymentOfCardPage />} />
                {/* 还款详情页 */}
                <Route path="/ptka-defa" element={<RepaymentOfCode />} />
                {/* 银行卡列表页 */}
                <Route path="/ptka-carm" element={<BindCardList />} />
                {/* 绑定新卡页 */}
                <Route path="/ptka-num" element={<BindCard />} />
            </Routes>
          </div>
      </Router>
      {/* <div className="App"></div> */}
    </Fragment>
  );
}

export default App;
