import React, { Component } from 'react';
import { setTitle } from "../../common/js/tools";
import { setNavExpansion } from '../../common/js/navigateTools';
import Header from "../../components/header";
import './index.less';
import logo from "../../common/images/official/logo.png";

import top1 from "../../common/images/official/Icon1.png";
import top2 from "../../common/images/official/Icon2.png";
import top3 from "../../common/images/official/Icon3.png";
import top4 from "../../common/images/official/Icon4.png";
import top5 from "../../common/images/official/Icon5.png";
import top6 from "../../common/images/official/Icon6.png";

class Official extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navList: [{
                id: "area1",
                name: "Home",
                isScroll: true,
            }, {
                id: "area3",
                name: "Lender Detail",
                isScroll: true,
            }, {
                id: "area4",
                name: "User Review",
                isScroll: true,
            }, {
                id: "area6",
                name: "Contact Us",
                isScroll: true
            }, {
                id: "privacy",
                name: "Privacy Policy",
                isScroll: false,
                jumpUrl: "https://summersetlending.com/wokrd/soleTa"
            }], // 顶部导航栏
            navIndex: 0, // 选中过的nav
            officialName: "Easy Pera", // 网站名
            sectionList: [{
                navIndex: 0,
                id: "area1",
                imgSrc: top1,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 1,
                id: "area2",
                imgSrc: top2,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 2,
                id: "area3",
                imgSrc: top3,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 3,
                id: "area4",
                imgSrc: top4,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 4,
                id: "area5",
                imgSrc: top5,
                isInteractive: false, // 是否交互
            },
                {
                    navIndex: 5,
                    id: "area6",
                    imgSrc: top6,
                    isInteractive: false, // 是否交互
                }
            ],
            isModal: false,
            iwidth: 0,
            clickedNavIndex: 0, // 点击的id
            scrolledNavIndex: 0, // 滑动的id
        }
    }
    componentDidMount() {
        setTitle(this.state.officialName);
        setNavExpansion("0"); // 不隐藏导航栏
        let width = document.querySelector('body').offsetWidth;
        this.setState({
            iwidth: width,
        })
        window.addEventListener("resize", this.resize.bind(this));
        window.addEventListener("scroll",  this.scrollToNav.bind(this), true);
    }
    // 点击滑动
    scrollToAnchor(item, index) {
        // console.log(item, index);
        this.setState({
            clickedNavIndex: index,
            navIndex: index == 2 ? 2 : this.state.scrolledNavIndex,
        });
        let elementId = item.id;
        let anchorElement = document.getElementById(elementId);
        if (anchorElement) {
            anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
    }
    // 监听宽度变化
    resize() {
        let width = document.querySelector('body').offsetWidth;
        this.setState({
            iwidth: width,
        });
    }
    // 跳出弹框
    showModal() {
        this.setState({
            isModal: true,
        })
    }
    // 关闭弹框
    closeModal() {
        this.setState({
            isModal: false,
        })
    }
    // 滑动到contact
    goContact() {
        this.scrollToAnchor({
            id: "about",
            name: "ABOUT"
        }, 4);
    }
    // 滑动到services
    goService() {
        this.scrollToAnchor({
            id: "services",
            name: "SERVICES"
        }, 2);
    }
    // 滑动页面时更换id
    scrollToNav(e) {
        let anchorElementHeights = [];
        this.state.navList.map(item => {
            let height = document.getElementById(item.id)?.offsetTop;
            anchorElementHeights.push(height);
        })
        // console.log(anchorElementHeights);
        anchorElementHeights.map((item, index) => {
            if(e.target.scrollTop >= item && e.target.scrollTop <= anchorElementHeights[index + 1] && e.target.scrollTop <= anchorElementHeights[anchorElementHeights.length - 1]) {
                this.setState({
                    scrolledNavIndex: index,
                    navIndex: this.state.clickedNavIndex == anchorElementHeights.length - 1 ? anchorElementHeights.length - 1 : index,
                });
            } else if(e.target.scrollTop >= anchorElementHeights[anchorElementHeights.length - 1]) {
                this.setState({
                    scrolledNavIndex: anchorElementHeights.length - 1,
                    navIndex: this.state.clickedNavIndex == anchorElementHeights.length - 1 ? anchorElementHeights.length - 1 : index,
                });
            }
        })
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", this.scrollToNav);
    }
    render() {
        return (
            <div className="P-official">
                <Header
                logoLeft
                logo={logo}
                name={this.state.officialName}
                list={this.state.navList}
                navIndex={this.state.navIndex}
                isWidth={this.state.iwidth}
                scrollToAnchor={this.scrollToAnchor.bind(this)}>
                    {/* 中间区域 child */}
                </Header>
                <section className={this.state.iwidth > 1045 ? "P-official_content distance" : "P-official_content mediaDistance"}>
                    {this.state.sectionList.map((item, index) => (
                        <div key={"vIndex" + index} className="content_area">
                            {!item.isInteractive ? (
                                <img src={item.imgSrc} id={item.id} className="content_area_img" />
                            ) : null}
                        </div>
                    ))}
                </section>
            </div>
        )
    }
}

export default Official;