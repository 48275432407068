import React from 'react';

const LoanAgreementCom = (props) => {
    return (
        <div className='M-loanAgreementCom'>
            <p>This Loan Agreement (referred to as "this Agreement") is made between Instant peso (referred to as "we" or "the App"), operated by TAWID LENDING CORPORATION (referred to as "the Loan Service Provider"), and you (referred to as "the Borrower"). Before proceeding with your loan application, please read and understand the terms carefully. These terms are designed to ensure that you fully comprehend the rights and obligations of both parties before applying for and using our loan services. </p>
            <p>Instant peso is committed to providing flexible loan services. Through our app, you can easily apply for a loan. Our intelligent application system simplifies the process, and our repayment management features help you track dates and amounts clearly, making it easier to manage your finances. </p>
            <div>
                <p className='subtitle'>Instant peso Loan Provider:</p>
                <p>- Company: TAWID LENDING CORPORATION</p>
                <p>- Website: <span className='email'>https://tawidlend.com</span></p>
                <p>- Address: Tawid, Candijay, Bohol, Philippines 6312</p>
                <p>- SEC.NO.CS201825429</p>
            </div>
            <div>
                <p className='subtitle'>Loan Application</p>
                <p>In the Instant peso app, you can select a loan amount based on your needs and credit status, provided it is within the specified range. We also offer flexible loan term options to accommodate your personal financial needs. You can choose a term that suits your preferences and repayment capacity, making the repayment process more adaptable.</p>
                <p>1. You must provide accurate and truthful personal information, including ID proof, contact details, bank account information, and income proof. Your income level is a key factor in determining repayment capacity.</p>
                <p>2. We will conduct a credit evaluation, considering various factors to ensure compliance with regulations and our loan policy. The final decision on loan approval is at our discretion.</p>
                <p>3. We will evaluate various factors to ensure compliance with regulations and our loan policy. Our diverse loan conditions aim to offer a personalized and considerate loan experience. For any questions or assistance, please contact our customer service team.</p>
                <p>After submitting your loan application, please wait for our review. We will notify you of the decision, and if approved, the loan amount will be credited to the card you provided.</p>
            </div>
            <div>
                <p className='subtitle'>Loan Issuance</p>
                <p>1. Amount: Based on your credit evaluation, ranges from 1,500 to 60,000 pesos.</p>
                <p>2. Loan term:  Ranges from a minimum of 91 days to a maximum of 365 days, depending on individual circumstances, and is calculated from the date the loan amount is credited to your designated bank account.</p>
                <p>3. Loan Interest Rate: Our annual percentage rate (APR) ranges from 15% to 31%, with the specific rate influenced by market conditions and your credit rating.</p>
            </div>
            <div>
                <p className='subtitle'>Repayment</p>
                <p>Repayment Date: The repayment date is the expiration date of the loan term. If this falls on a holiday, the repayment date will be extended to the next business day.</p>
            </div>
            <div>
                <p className='subtitle'>Handling Overdue Repayments</p>
                <p>1. Overdue Interest: Failure to repay on time may incur overdue repayment fees, calculated based on the overdue days and unpaid amount.</p>
                <p>2. Overdue Fees: You are responsible for any additional fees incurred due to overdue repayment, including collection and legal fees.</p>
                <p>We reserve the right to take measures, including legal action and credit reporting, to recover overdue amounts.</p>
            </div>
            <div>
                <p className='subtitle'>Borrower's Rights and Obligations</p>
                <p>1. Information: You must provide true, accurate, and complete information and update it promptly if there are any changes. Providing false information may affect loan approval and service rights.</p>
                <p>2. Timely Repayment: You are responsible for making full and timely repayments and maintaining a good credit record. Late repayments may result in additional fees and negatively impact your credit record.</p>
                <p>3. Legal Use of Loan: You must use the loan for legal purposes only and avoid engaging in illegal activities. Use our services responsibly to prevent abuse or interference.</p>
            </div>
            <div>
                <p className='subtitle'>Dispute Resolution</p>
                <p>We reserve the right to amend the terms of this Agreement based on legal regulations and business needs, notifying you through the App. The amended Agreement takes effect from the date of notification.</p>
                <p>In extreme cases, we may terminate or suspend part or all of our services. We will notify you appropriately and fulfill our obligations within the legal scope. This Agreement will automatically terminate once you have repaid all debts and fees.</p>
                <p>In the event of a dispute arising from this Agreement, both parties should first attempt to resolve it through friendly negotiation.</p>
                <p>If negotiation fails, either party may file a lawsuit in a court with jurisdiction over the App's location.</p>
                <p>If both parties agree, disputes may be resolved through arbitration. The arbitration decision is final and binding. If any provision is deemed invalid due to inconsistencies with applicable laws, it will not affect the validity of other provisions. Invalid provisions will be replaced or interpreted to comply with the law.</p>
                <p>Thank you for reading our terms of use. We have designed this Agreement to ensure a clear and transparent loan experience. If you have any questions or need assistance during the loan application process, please contact our customer service team.</p>
            </div>
            <div>
                <p className='subtitle'>Contact us</p>
                <p>- Email: <span className='email'>tawidlen@outlook.com</span></p>
            </div>
        </div>
    )
}

export default LoanAgreementCom;