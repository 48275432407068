import React, { useState } from 'react';
import './index.less';
import closeIcon from "../../common/images/closeIcon.png";

const Modal = (props) => {
    const { titleContent, close, children, isBottom } = props;
    return (
        <div className='M-modal'>
            <div className='M-modal_mask'></div>
            <div className={isBottom ? 'M-modal_content bottom' : 'M-modal_content'}>
                <div className='top'>
                    <p>{titleContent || ""}</p>
                </div>
                <img src={closeIcon} className='closeIcon' onClick={() => close()} />
                <div className='content'>
                    <div className='scrollArea'>
                        <div className='list'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;