export default {
    loanDetailConfirm: "/smile/jiggingThere", // 确认用款页详情
    loanDetailPush: "/smile/ohOh", // 确认用款
    loanDetails: "/smile/peoplesPaper", // 订单详情页
    googleScore: "/smile/turnitWhats", // google评分
    userReloan: "/smile/dressinggownMinute", // 原卡重试确认订单
    loanPlayDetail: "/smile/theresDrawingboard", // 获取还款计划信息
    getLoanPayment: "/smile/circusWrapped", // 获取还款方式
    delayDetail: "/smile/placePapers", // 展期详情页
    cardInit: "/smile/hurryPlates", // 获取绑卡信息（第五项）
    bindCardNew: "/smile/hedgeRiding", // 提交绑卡（第五项）
    userCardList: "/smile/julianThree", // 用户银行卡列表
    changeOrderAccount: "/smile/aboutMischief", // 更换银行卡（会触发重新打款）
    contactKfInfo: "/smile/possiblyPlease", // 获取客服页面数据
    generateVcode: "/smile/thingThrough", // 获取还款code码
    autoConfirmButton: "/smile/pieceAbout", // 取消自动确认用款
    // prohibitedProduct: "/uiengllli", // 禁止产品详情 - 小黑屋
}