import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import Modal from "../modal";
import { loanPlayDetailService } from "../../axios/service";
import Button from "../button";
import "./index.less";

const RepaymentOfPeriodCom = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `realize=${params.realize}&notch=${params.notch}`;

    const [productName, setProductName] = useState("");
    const [repayPlanDetail, setRepayPlanDetail] = useState({}); // 内涵details
    const [totalAmount, setTotalAmount] = useState(""); // 总金额
    const [amount, setAmount] = useState(null); // 带货币符号的总金额
    const [note, setNote] = useState("");

    useEffect(() => {
        const getPeriod = async () => {
            let data = await loanPlayDetailService();
            setProductName(data.waspulled || "");
            setRepayPlanDetail(data.anddry || {});
            setTotalAmount(data.scream || "");
            setAmount(data.islandfrom || "");
            setNote(data.exclamation || "");
        }
        getPeriod();
    }, [])

    // 跳转还款方式页面
    const goReay = () => {
        props.closeFunc();
        let ip_amount = repayPlanDetail && repayPlanDetail.regards && repayPlanDetail.regards[0].scream || ""; // 还款接口需要的金额
        window.location.href = `/ptka-func?amount=${ip_amount}&isDelay=${0}&offinding=${params.offinding}&havesulked=${params.havesulked}&${paramsDetail}`;
    }
    return (
        <Modal titleContent="" close={() => props.closeFunc()}>
            <div className="M-period">
                {repayPlanDetail && repayPlanDetail.regards && repayPlanDetail.regards.length > 0 ? (
                    <ul className="M-period_list">
                        {repayPlanDetail.regards.map((item, index) => {
                            return (
                                <li key={"pePlanI" + index}>
                                    <div className="list">
                                        <div className="list_1">
                                            <p>{item.soundly}</p>
                                            <p>{item.learnnot}</p>
                                        </div>
                                        <div className="list_2">
                                            <p>{item.catching}</p>
                                            <p>{item.undressed}</p>
                                        </div>
                                    </div>
                                    {index == 0 ? (
                                        <p className="first_tips">{note || "If the first installment is repaid on time, the second installment will be waived."}</p>
                                    ) : null}
                                </li>
                            )
                        })}
                    </ul>
                ) : null}
                <div className="M-period_button">
                    <Button
                    content="Repay"
                    clickFunc={() => goReay()} />
                </div>
            </div>
        </Modal>
    )
}

export default RepaymentOfPeriodCom;