import React, { useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../common/js/tools";
import { ToastType } from "../../common/js/toastList";
import { loanDetailsService, googleScoreService, userReloanService } from "../../axios/service";
import { setNavExpansion, openUrl, toGrade, jumpToHome, closeSyn, jumpToEmailNative, setNavColor } from '../../common/js/navigateTools';
import Button from "../../components/button";
import ErrorModal from "../../components/errorModal";
import SafeArea from "../../components/safeArea";
import RepaymentOfPeriodCom from "../../components/repaymentOfPeriodCom";
import "./loanDetails.less";
import scoreStarNotChoosed_01 from "../../common/images/score/scoreStarNotChoosed_01.png";
import scoreStarNotChoosed_02 from "../../common/images/score/scoreStarNotChoosed_02.png";
import scoreStarNotChoosed_03 from "../../common/images/score/scoreStarNotChoosed_03.png";
import scoreStarNotChoosed_04 from "../../common/images/score/scoreStarNotChoosed_04.png";
import scoreStarNotChoosed_05 from "../../common/images/score/scoreStarNotChoosed_05.png";
import scoreStarChoosed_01 from "../../common/images/score/scoreStarChoosed_01.png";
import scoreStarChoosed_02 from "../../common/images/score/scoreStarChoosed_02.png";
import scoreStarChoosed_03 from "../../common/images/score/scoreStarChoosed_03.png";
import scoreStarChoosed_04 from "../../common/images/score/scoreStarChoosed_04.png";
import scoreStarChoosed_05 from "../../common/images/score/scoreStarChoosed_05.png";
import score_top from "../../common/images/score/score_top.png";
import score_closeIcon from "../../common/images/score/score_closeIcon.png";

import status21 from "../../common/images/status/status21.png";
import status110 from "../../common/images/status/status110.png";
import status151 from "../../common/images/status/status151.png";
import status174 from "../../common/images/status/status174.png";
import status200 from "../../common/images/status/status200.png";

const LoanDetails = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `realize=${params.realize}&notch=${params.notch}`;

    const [orderStatus, setOrderStatus] = useState(174); // 订单状态  110"额度审核失败" ,151"放款中",170"放款成功(待还款),174"待还款,169"放款失败",175"还款中",179"还款失败",200"贷款结清通知",180"逾期通知",21 审核中，110审核不通过，22待绑卡，120待提现，10等待补充信息认证
    const [noticeStatusText, setNoticeStatusText] = useState(""); // status txt
    const [noticeDesText, setNoticeDesText] = useState(""); // status desc
    const [productName, setProductName] = useState(""); // 产品name
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [detail, setDetail] = useState([]); // 用户信息数据列表
    const [goLoanUrl, setGoLoanUrl] = useState(""); // 再来一单url
    const [orderNo, setOrderNo] = useState(""); // 订单order_no
    const [loanFailed, setLoanFailed] = useState({}); // 付款失败按钮显示内容 == 169
    const [scoreList, setScoreList] = useState([{
        notChoosedImg: scoreStarNotChoosed_01,
        choosedImg: scoreStarChoosed_01,
        choosedStatus: false,
    }, {
        notChoosedImg: scoreStarNotChoosed_02,
        choosedImg: scoreStarChoosed_02,
        choosedStatus: false,
    }, {
        notChoosedImg: scoreStarNotChoosed_03,
        choosedImg: scoreStarChoosed_03,
        choosedStatus: false,
    }, {
        notChoosedImg: scoreStarNotChoosed_04,
        choosedImg: scoreStarChoosed_04,
        choosedStatus: false,
    }, {
        notChoosedImg: scoreStarNotChoosed_05,
        choosedImg: scoreStarChoosed_05,
        choosedStatus: false,
    }]); // 评分列表
    const [isScore, setIsScore] = useState(0); // 是否显示评分弹框
    const [clickIndex, setClickIndex] = useState(0); // 评分分数
    const [isDelay, setIsDelay] = useState(0); // 是否展示展期按钮

    // const [bankCard, setBankCard] = useState(""); // 银行账户号
    // const [bankName, setBankName] = useState(""); // 银行名称
    // const [bankText, setBankText] = useState(""); // 银行卡文案
    const [loan_mode, setLoanMode] = useState([]); // 还款信息列表
    const [bank, setBank] = useState([]); // 银行卡列表

    // 放款失败 银行报错弹框
    const [isBoxBank, setIsBoxBank] = useState(0); // 是否弹框 1弹 0不弹
    const [loanFailedTxt, setLoanFailedTxt] = useState(""); // 弹框里的文字
    const [loanFailedTitle, setLoanFailedTitle] = useState(""); // 弹框里的标题
    const [loanFailedType, setLoanFailedType] = useState(null); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡

    // 待还款，逾期状态未更新
    const [isBoxStatusNoUpdate, setIsBoxStatusNoUpdate] = useState(0); // 是否弹框 1弹 0不弹
    const [statusNoUpdateTxt, setStatusNoUpdateTxt] = useState(""); // 弹框里的文字
    const [statusNoUpdateTitle, setStatusNoUpdateTitle] = useState(""); // 弹框里的标题
    const [emailTitle, setEmailTitle] = useState(""); // 邮箱的标题
    const [email, setEmail] = useState(""); // 邮箱地址

    const [isRepayPeriod, setIsRepayPeriod] = useState(0); // 是否展示单期还款弹框
    const [overdueDays, setOverdueDays] = useState(""); // 逾期天数

    const statusArr = [{
        statusIndex: 21, // 审核中
        statusImg: status21,
    }, 
    // {
    //     statusIndex: 110, // 审核失败
    //     statusImg: status110,
    // }, 
    {
        statusIndex: 151, // 放款中
        statusImg: status151,
    }, 
    // {
    //     statusIndex: 169, // 放款失败
    //     statusImg: status110,
    // }, 
    {
        statusIndex: 174, // 待还款
        statusImg: status174,
    }, {
        statusIndex: 180, // 逾期
        statusImg: status174,
    }, {
        statusIndex: 200, // 已结清
        statusImg: status200,
    }]
    useEffect(() => {
        setTitle("Loan Details");
        setNavExpansion(0); // 是否隐藏导航栏
        setNavColor("#2D2D2D", "#FDDD00");

        // 获取订单详情
        const getLoanData = async () => {
            let data = await loanDetailsService();
            // console.log(data);
            setOrderStatus(data.flames || ""); // data.stories || ""
            setNoticeStatusText(data.anywhere || "");
            setNoticeDesText(data.gotpapers || "");
            setProductName(data.waspulled || "");
            setProductLogo(data.doorway || "");
            setDetail(data.thirsty && data.thirsty.scrape || []);
            setOrderNo(data.professorhayling || "");
            setLoanFailed(data.dreams || {});
            setGoLoanUrl(data.didnot || "");
            setIsScore(data.recognized || 0);
            setIsDelay(data.herquick || 0);

            // setBankCard(data. || "");
            // setBankName(data. || "");
            setLoanMode(data.thirsty && data.thirsty.giggle || []);
            setBank(data.thirsty && data.thirsty.noiseoutside || []);

            // 放款失败 银行报错弹框
            setIsBoxBank(data.oneclimbing || 0); // 是否弹框 1弹 0不弹 
            setLoanFailedTxt(data.hecan || ""); // 弹框里的文字
            setLoanFailedTitle(data.struggle || ""); // 弹框里的标题
            setLoanFailedType(data.thatwhen || 0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡 

            // 待还款，逾期状态未更新
            setIsBoxStatusNoUpdate(data.softly || 0); // 是否弹框 1弹 0不弹 
            setStatusNoUpdateTxt(data.clouds || "") // 弹框里的文字
            setStatusNoUpdateTitle(data.strummmm || "") // 弹框里的标题
            setEmailTitle(data.strum || ""); // 邮箱的标题
            setEmail(data.thinkyou || ""); // 邮箱地址

            setOverdueDays(data.howyou || ""); // 逾期天数
        }
        getLoanData();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // 去还款页 - 单期
    const goPayPeriod = () => {
        // navigate(`/towardsHeavy?offinding=${params.offinding}&havesulked=${params.havesulked}&${paramsDetail}`);
        // window.location.href = `/towardsHeavy?offinding=${params.offinding}&havesulked=${params.havesulked}&${paramsDetail}`;
        setIsRepayPeriod(1);
    }
    // 去还款页 - 展期
    const goPayDelay = () => {
        // navigate(`/ptka-lony?offinding=${params.offinding}&havesulked=${params.havesulked}&${paramsDetail}`);
        window.location.href = `/ptka-lony?offinding=${params.offinding}&havesulked=${params.havesulked}&${paramsDetail}`;
    }
    // 跳转换绑卡列表（更换账户）
    const goBindCardList = () => {
        // 跳转 换绑卡（更换账户）
        if (loanFailed.camps.verymuch != undefined && loanFailed.camps.verymuch.length > 0) {
            // navigate(`${loanFailed.camps.verymuch}&${paramsDetail}`);
            window.location.href = `${loanFailed.camps.verymuch}&${paramsDetail}`;
        }else {
            // navigate(`/ptka-carm?offinding=${orderNo}&havesulked=${params.havesulked}&${paramsDetail}`);
            window.location.href = `/ptka-carm?offinding=${orderNo}&havesulked=${params.havesulked}&${paramsDetail}`;
        }
    }
    // 原卡重试
    const reTryOriginCard = async () => {
        let data = await userReloanService({
            orderNo: orderNo,
        });
        // console.log(data);
        // navigate(`${data.findthem}&${paramsDetail}`); // 跳转订单详情页
        window.location.href = `${data.findthem}&${paramsDetail}`;
    }
    // 再来一单
    const goToLoan = () => {
        // 跳转原生页面
        let url_type = goLoanUrl.slice(0, 4);
        if(url_type == "http") {
            window.location.href = `${goLoanUrl}&${paramsDetail}`;
        } else if(url_type == "smil") {
            openUrl(`${goLoanUrl}`); // ?contain=params.havesulked
        }
    }
    // 回到首页
    const goHome = () => {
        jumpToHome();
    }
    // 关闭评分弹框
    const closeScoreModal = () => {
        setIsScore(0);
    }
    // 点击评分
    const clickScore = (item, index) => {
        let the_scoreList = scoreList;
        the_scoreList.map((child, i) => {
            if(i == index) {
                return the_scoreList[i].choosedStatus = true;
            } else {
                return the_scoreList[i].choosedStatus = false;
            }
        });
        setScoreList(the_scoreList);
        setClickIndex(index + 1);
    }
    // 提交评分
    const upScore = async () => {
        if(clickIndex == 0) {
            ToastType('Toast', 'Please select');
            return;
        }
        let data = await googleScoreService({
            clickIndex: clickIndex,
        });
        toGrade();
        setIsScore(0);
        setClickIndex(0);
    }
    // 跳转换绑卡
    const changeAccount = () => {
        // navigate(`/ptka-carm?offinding=${orderNo}&havesulked=${params.havesulked}&${paramsDetail}`);
        window.location.href = `/ptka-carm?offinding=${orderNo}&havesulked=${params.havesulked}&${paramsDetail}`;
    }
    // 关闭 放款失败银行错误弹框
    const closeBankModal = () => {
        closeSyn(); // 关闭当前H5
    }
    // 关闭 还款状态未更新弹框
    const closeStatusModal = () => {
        setIsBoxStatusNoUpdate(0);
    }
    // 跳转带标题和订单号的邮箱 js交互
    const jumpToEmailNativigate = () => {
        jumpToEmailNative(email, emailTitle, orderNo);
    }

    return (
        <div className="P-loanDetails">
            {/* <SafeArea /> */}
            {/* 状态描述区域 */}
            <div className="P-loanDetails_status">
                {statusArr.map((item, index) => {
                    return (
                        orderStatus == item.statusIndex && Object.keys(loanFailed).length == 0 ? 
                        <div className="statusArea" key={"deStatusI" + index}>
                            <img src={item.statusImg} className="statusArea_statusImg" />
                            <p className="statusArea_desc">{noticeStatusText || ""}</p>
                            <p className="statusArea_tips">
                                {noticeDesText || ""}
                                {orderStatus == 180 ? <span> You are {overdueDays || "-"} days overdue</span> : null}
                            </p>
                        </div> : null
                    )
                })}
                {/* 审核失败 & 放款失败 */}
                {orderStatus == 110 || orderStatus == 169 || (orderStatus == 151 && Object.keys(loanFailed).length > 0 && loanFailed.cloud == 1) ? (
                    <div className="statusArea failStatus">
                        <img src={status110} className="statusArea_statusImg" />
                        <p className="statusArea_desc">{noticeStatusText || ""}</p>
                        <p className="statusArea_tips">{noticeDesText || ""}</p>
                    </div>
                ) : null}
            </div>
            {/* 信息展示区域 */}
            <div className="P-loanDetails_info">
                {/* 还款信息列表 */}
                {loan_mode && loan_mode.length > 0 ? (
                    <ul className="info_dataArea">
                        {loan_mode.map((item, index) => {
                            return (
                                <li key={"deRepay" + index}>
                                    <p className="left">{item.charts}</p>
                                    <p className="right">{item.scribble}</p>
                                </li>
                            )
                        })}
                    </ul>
                ) : null}
                {/* 常规信息列表 */}
                {detail && detail.length > 0 ? (
                    <ul className="info_dataArea">
                        {detail.map((item, index) => {
                            return (
                                <li key={"deInfo" + index}>
                                    <p className="left">{item.charts}</p>
                                    <div className="right r_logo">
                                        {item.thati && item.thati.length > 0 ? <img src={item.thati} /> : null}
                                        <p>{item.scribble}</p>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                ) : null}
                {/* 银行卡信息列表 */}
                {bank && bank.length > 0 ? (
                    <ul className="info_dataArea" style={{border: "none"}}>
                        {bank.map((item, index) => {
                            return (
                                <li key={"deBank" + index}>
                                    <p className="left">{item.charts}</p>
                                    <p className="right">{item.scribble}</p>
                                </li>
                            )
                        })}
                    </ul>
                ) : null}
            </div>
            {/* 按钮 */}
            <div className="P-loanDetails_button paperBtn">
                {/* 审核失败 - 返回首页 */}
                {orderStatus == 110 && (
                    <div className="buttonArea">
                        <Button
                        content= "Homepage"
                        clickFunc= {() => goHome()} />
                    </div>
                )}
                {/* 再来一单 */}
                {orderStatus == 200 && goLoanUrl.length > 0 && (
                    <div className="buttonArea">
                        <Button
                        content= "Apply Again"
                        clickFunc= {() => goToLoan()} />
                    </div>
                )}
            </div>
            {/* 按钮 */}
            {orderStatus == 174 || orderStatus == 180 ? (
                <div className="P-loanDetails_button">
                    {/* 延期还款 */}
                    {isDelay ? 
                        <div className="buttonArea">
                            <Button
                            content= "Extension"
                            clickFunc= {() => goPayDelay()}
                            btnStyle= {{background: "#09C8AF", color: "#FFFFFF", border: "2px solid #09C8AF"}} />
                        </div> : null}
                    {/* 正常还款 */}
                    <div className="buttonArea">
                        <Button
                        content= "Repay"
                        clickFunc= {() => goPayPeriod()} />
                    </div>
                </div>
            ) : null}
            {orderStatus == 169 || (orderStatus == 151 && loanFailed && Object.keys(loanFailed).length > 0 && loanFailed.cloud == 1) ? (
                <div className="P-loanDetails_button">
                {/* 原卡重试 */}
                {loanFailed.werelost && loanFailed.werelost.shaking == 1 ? 
                    <div className="buttonArea">
                        <Button
                        content= {loanFailed.werelost.widely}
                        clickFunc= {() => reTryOriginCard()}
                        btnStyle= {{background: "#09C8AF", color: "#FFFFFF", border: "2px solid #09C8AF"}}  />
                    </div> : null}
                {/* 更换账户 */}
                {loanFailed.camps && loanFailed.camps.shaking == 1 ?
                    <div className="buttonArea">
                        <Button
                        content= {loanFailed.camps.widely}
                        clickFunc= {() => goBindCardList()} />
                    </div> : null}
                </div>
            ) : null}

            {/* 评分弹框 */}
            {isScore ? (
                <div className="P-loanDetails_score">
                    <div className="score_mask"></div>
                    <div className="score_info">
                        <img src={score_top} className="score_topIcon" />
                        <div className="info">
                            <p className="info_title">Give us a rating</p>
                            <p className="info_desc">Your support is a constant motivation for us to keep improving~</p>
                            <div className="star">
                                {scoreList.map((item, index) => {
                                    return (
                                        <img src={item.choosedStatus ? item.choosedImg : item.notChoosedImg} key={"scoreStar" + index} onClick={() => clickScore(item, index)} className={item.choosedStatus ? "rotate_star" : ""} />
                                    )
                                })}
                            </div>
                            <div className="score_buttonArea">
                                <Button
                                content= "Confirm"
                                clickFunc= {() => upScore()} />
                            </div>
                        </div>
                        <img src={score_closeIcon} className="closeIcon" onClick={() => closeScoreModal()} />
                    </div>
                </div>
            ) : null}

            {/* 银行卡错误提示弹框 */}
            {isBoxBank ? (
                <ErrorModal
                isClose
                closeFunc={() => closeBankModal()}
                loanFailedType={loanFailedType}
                loanFailedTitle={loanFailedTitle}
                loanFailedTxt={loanFailedTxt}
                btnArray={[{
                    content: "Replace",
                    clickFunc: () => changeAccount(),
                    btnStyle: {background: "#2D2D2D", color: "#FAFAFA", border: "2px solid #2D2D2D"}
                }]} />
            ) : null}

            {/* 单期还款弹框 */}
            {isRepayPeriod ? 
            <RepaymentOfPeriodCom
            closeFunc={() => setIsRepayPeriod(0)} /> : null}
        </div>
    )
}

export default LoanDetails;