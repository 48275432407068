import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion } from '../../common/js/navigateTools';
import './agreement.less';

const PrivacyAgreement = (props) => {
    useEffect(() => {
        // 隐私协议
        setTitle('Privacy Agreement');
        setNavExpansion(0);
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="P-agreement">
            <p className="title">Privacy Policy</p>
            <div>
                <p className='subtitle'>1. Introduction</p>
                <p>Welcome to the Instant peso application (hereinafter referred to as "we" or "the App"). We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, store, and share your personal information. By accepting this Privacy Policy, you agree to its terms. Please read this policy carefully to understand our privacy protection practices.</p>
            </div>
            <div>
                <p className="title">2. Service Provider</p>
                <p>Instant peso Loan Provider:</p>
                <p>- Company: TAWID LENDING CORPORATION</p>
                <p>- Website: <span className='email'>https://tawidlend.com</span></p>
                <p>- Address: Tawid, Candijay, Bohol, Philippines 6312</p>
                <p>- SEC.NO.CS201825429</p>
                <p>As your trusted loan service provider, we recognize the importance of personal privacy protection and adhere strictly to relevant Philippine and international data protection laws and regulations. We have implemented rigorous security measures to safeguard your personal information. We are committed to respecting and protecting your privacy throughout the loan service process, ensuring that your personal information is handled and protected properly.</p>
            </div>
            <div>
                <p className="title">3. Information We Collect</p>
                <p>To offer more convenient and personalized services, we may collect some of your information as part of our customer identification process. Your agreement to our Terms of Use and Privacy Policy governs how we handle your information. We pledge not to share your information with any partners unless absolutely necessary. We may collect and process the following types of personal information:</p>
                <p>- Application Information: Includes your name, ID number, contact details (such as phone number and email address), and address.</p>
                <p>- Financial Information: During the loan approval process, we will collect and process information related to your financial status, including but not limited to income, expenses, and credit history, for the purpose of personalized loan approval.</p>
                <p>-Usage Information: Includes logs of your use of the App, access records, and device information (such as IP address, device model, and operating system version).</p>
            </div>
            <div>
                <p className="title">4. Permissions We Need:</p>
                <p>- Camera Access: We require access to your camera to take photos of your ID for identity verification and fraud prevention.</p>
                <p>- Album Access: To ensure the authenticity of your identification documents and mitigate fraud risks, we need access to your phone's album to conveniently access your ID documents.</p>
                <p>- Location Information: To offer more accurate and personalized services, we may collect your geographic location information. This helps ensure you receive relevant offers, events, or services related to your current location. We are committed to handling and using your location information with the highest security standards to prevent potential fraud.</p>
                <p>- Facial Recognition Data: For identity verification, we collect biometric data but promise to store it only on our servers for 14 days before it is automatically deleted.</p>
                <p>- Personalized Ads and Recommendations: We collect phone numbers, geographic locations, advertising identifiers, etc., to provide personalized advertising and recommendation services. We implement a series of anti-fraud measures, including data encryption, behavioral analysis, artificial intelligence screening, risk assessment, and user permission controls to ensure account security.</p>
            </div>
            <div>
                <p className="title">5. How We Use Your Information</p>
                <p>We use the collected information for the following purposes:</p>
                <p>- Providing Services: Processing your loan application, managing your account, conducting credit assessments, and risk management.</p>
                <p>- Customer Support: Responding to your inquiries, providing technical support, and solutions.</p>
                <p>- Improving Services: Analyzing usage and user feedback to optimize and improve our services.</p>
                <p>- Legal Compliance: Complying with legal and regulatory requirements.</p>
            </div>
            <div>
                <p className="title">6. Sharing and Disclosure of Information</p>
                <p>We will not share your personal information with any third parties except in the following situations:</p>
                <p>- With Your Consent: When you have explicitly agreed.</p>
                <p>- Legal Requirements: When required by laws, regulations, or regulatory requirements.</p>
            </div>
            <div>
                <p className="title">7. Information Storage and Protection</p>
                <p>We take reasonable technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. We will retain your personal information in accordance with applicable laws and regulations, but will securely delete or anonymize your information when it is no longer needed.</p>
            </div>
            <div>
                <p className="title">8. Your Rights</p>
                <p>You have the right to:</p>
                <p>- Access and Update: Access and update your personal information.</p>
                <p>- Deletion: Request the deletion of your personal information unless we have a legitimate reason to retain it.</p>
                <p>- Objection: Object to our processing of your personal information.</p>
            </div>
            <div>
                <p className="title">9. Changes to the Privacy Policy</p>
                <p>We reserve the right to update this policy at any time. Changes will be communicated through notifications or by updating the date within the app. To stay informed of policy changes and make informed decisions, we recommend regularly reviewing this policy.</p>
            </div>
            <div>
                <p className="title">10. Minors' Privacy</p>
                <p>Our services are intended for adults aged 18 or older. We do not knowingly collect or retain personal information from children under the age of 18. If we become aware that a child under 18 has provided personal information, we will delete such information. If you are a parent or guardian and know that your child has provided us with personal information, please contact us so we can promptly delete it.</p>
            </div>
            <div>
                <p className="title">11. Contact Us</p>
                <p>If you have any questions about this Privacy Policy or need to exercise your rights, please contact us</p>
                <p>- Email: <span className='email'>tawidlen@outlook.com</span></p>
            </div>
        </div>
    );
}

export default PrivacyAgreement;

