import http from "./axios";
import apiUrl from "./api";
// import qs from 'qs';
import { Qs, uuid } from "../common/js/tools";
const params = Qs(); // 获取params
// 确认用款页详情
export const loanDetailConfirmService = () => {
    return http.post(apiUrl.loanDetailConfirm, {
        havesulked: params.havesulked, // 产品id
    }, {
        havesulked: params.havesulked, // 产品id
        absolutelycolossal: uuid(), // 混淆字段
    });
}
// 确认用款
export const loanDetailPushService = (data) => {
    let { amount, periods, termUnit } = data;
    return http.post(apiUrl.loanDetailPush, {}, {
        islandfrom: amount, // 金额
        syour: periods, // 期限
        begged: termUnit, // 期限类型
        havesulked: params.havesulked, // 产品id
        switching: uuid(), // 混淆字段
    });
}
// 订单详情页
export const loanDetailsService = () => {
    return http.get(apiUrl.loanDetails, {
        offinding: params.offinding, // 订单id
        havesulked: params.havesulked, // 产品id
    }, {
        havesulked: params.havesulked, // 产品id
        offinding: params.offinding, // 订单id
        courtyard: uuid(), // 混淆字段
    });
}
// google评分
export const googleScoreService = (data) => {
    let { clickIndex } = data
    return http.post(apiUrl.googleScore, {}, {
        toyour: clickIndex, // 分数 1-5
        spider: uuid(), // 混淆字段
    });
}
// 原卡重试确认订单
export const userReloanService = (data) => {
    let { orderNo } = data;
    return http.post(apiUrl.userReloan, {}, {
        visited: orderNo, // 订单号
    });
}
// 获取还款计划信息
export const loanPlayDetailService = () => {
    return http.post(apiUrl.loanPlayDetail, {}, {
        offinding: params.offinding, // 订单id
        havesulked: params.havesulked, // 产品id
    });
}
// 获取还款方式
export const getLoanPaymentService = () => {
    return http.post(apiUrl.getLoanPayment, {
        offinding: params.offinding, // 订单id
        havesulked: params.havesulked, // 产品id
        amount: params.amount, // 传参金额
    }, {
        offinding: params.offinding, // 订单id
        havesulked: params.havesulked, // 产品id
    });
}
// 展期详情页
export const delayDetailService = () => {
    return http.get(apiUrl.delayDetail, {
        offinding: params.offinding, // 订单id
        havesulked: params.havesulked, // 产品id
    }, {
        offinding: params.offinding, // 订单id
        havesulked: params.havesulked, // 产品id
    });
}
// 获取绑卡信息（第五项）
export const cardInitService = () => {
    return http.get(apiUrl.cardInit, {
        mistake: 1, // 是否添加新卡（app认证项给0，h5添加新卡给1）
    }, {
        mistake: 1, // 是否添加新卡（app认证项给0，h5添加新卡给1）
        emptied: uuid(), // 混淆字段
    });
}
// 提交绑卡（第五项）
export const bindCardNewService = (data) => {
    return http.post(apiUrl.bindCardNew, {}, data);
}
// 用户银行卡列表
export const userCardListService = () => {
    return http.post(apiUrl.userCardList, {}, {});
}
// 更换银行卡（会触发重新打款）
export const changeOrderAccountService = (data) => {
    let { bindId } = data;
    return http.post(apiUrl.changeOrderAccount, {}, {
        places: bindId || "", // 绑卡id
        professorhayling: params.offinding || "", // 订单号 （订单列表、订单详情等页面需要给，个人中心这种拿不到的不用给）
    });
}
// 获取客服页面数据
export const contactKfInfoService = () => {
    return http.post(apiUrl.contactKfInfo, {}, {
        roundtinker: uuid(), // 混淆字段
    });
}
// 获取还款code码
export const generateVcodeService = (from, data) => {
    return http.post(apiUrl.generateVcode, {
        offinding: params.offinding, // 订单id
        theworst: params.havesulked, // 产品id
    }, {
        offinding: params.offinding, // 订单id
        theworst: params.havesulked, // 产品id
        islandfrom: from == 'card' ?  data.amount : params.amount, // 金额
        herquick: from == 'card' ? data.isDelay : params.isDelay, // 是否展期 1是 0否 暂无展期默认先写0吧
        jabbered: from == 'card' ? parseInt(data.cardType) : parseInt(params.cardType), // 还款方式 1 钱包 2 银行 3 线下门店
        withme: from == 'card' ? data.typeCode : params.typeCode, // 申请code代码
        cuddle: uuid(), // 混淆字段
    });
}
// 取消自动确认用款
export const autoConfirmButtonService = (data) => {
    const { orderNo } = data;
    return http.post(apiUrl.autoConfirmButton, {}, {
        courteously: -1, // 类型，取消传 -1
        professorhayling: orderNo, // 订单order_no
        courtyard: uuid(), // 混淆字段
    })
}
// 禁止产品详情 - 小黑屋
/*export const prohibitedProductService = () => {
    return http.post(apiUrl.prohibitedProduct, {
        offinding: params.offinding, // 订单id
        havesulked: params.havesulked, // 产品id
    }, {
        beats: params.havesulked, // 产品id
        impudent: uuid(), // 混淆字段
        cousinship: uuid(), // 混淆字段
    })
}*/