import React, {useState, useEffect, Fragment} from "react";
// import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../common/js/tools";
import { ToastType } from "../../common/js/toastList";
import { loanDetailConfirmService, loanDetailPushService, autoConfirmButtonService } from "../../axios/service";
import { uploadRiskLoan, setNavExpansion, setNavColor } from "../../common/js/navigateTools";
import Button from "../../components/button";
import ErrorModal from "../../components/errorModal";
import Modal from "../../components/modal";
import LoanAgreementCom from "../../components/loanAgreementCom";
import SafeArea from "../../components/safeArea";
import './confirmOfLoan.less';

import bankError_confirm_errorIcon from "../../common/images/bankError/bankError_confirm_errorIcon.png";
import bankError_confirm_limitIcon from "../../common/images/bankError/bankError_confirm_limitIcon.png";
import bankError_confirm_normalIcon from "../../common/images/bankError/bankError_confirm_normalIcon.png";
import confirm_goBank from "../../common/images/confirmOfLoan/arrow.png";
import payment_icon from "../../common/images/bindCard/payment-icon.png";
// import confirm_countdownIcon from "../../common/images/confirmOfLoan/confirm_countdownIcon.png";
import amount_icon from "../../common/images/confirmOfLoan/amount_icon.png";
import card_notChecked from "../../common/images/repay/card_notChecked.png";
import card_checked from "../../common/images/repay/card_checked.png";
import shelfImg from "../../common/images/confirmOfLoan/shelfImg.png";
import ThatAll from "../../common/images/bindCard/thatall.png";
import TimeIcon from "../../common/images/bindCard/time.png";
import DateIcon from '../../common/images/bindCard/date.png'

const ConfirmOfLoan = (props) => {
    // 路由params
    const location = useLocation();
    // console.log(qs.parse(location.search.substring(1, location.search.length)));
    // const params = Qs();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `realize=${params.realize}&notch=${params.notch}`;

    // 跳转路由
    const navigate = useNavigate();

    var timer = null;
    // state数据
    const [amount, setAmount] = useState(""); // 传值时传的字段 不带金额符号的借款金额
    const [periods, setPeriods] = useState(""); // 期限 - 传值
    const [termUnit, setTermUnit] = useState(""); // // 期限类型 - 传值
    const [amountTxt, setAmountTxt] = useState(""); // 带金额符号的借款金额
    // const [amount_show, setAmountShow] = useState(""); // 展示用金额 （有逗号没有金额符号）
    const [details, setDetails] = useState([]); // 借款数据
    const [loanTerms, setLoanTerms] = useState([]);
    const [repay_plans, setRepayPlans] = useState([]); // 还款计划
    const [note, setNote] = useState(""); // 首期还款即结清提示
    const [firstPayTime, setFirstPayTime] = useState(""); // 第一期还款时间
    const [firstPeriodDay, setFirstPeriodDay] = useState(""); // 第一期借款期限
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [productName, setProductName] = useState(""); // 产品名字
    const [isChekedAgreement, setIsChekedAgreement] = useState(true); // 借款协议的选择状态
    const [cardType, setCardType] = useState(1); // 卡的类型 1.ewallet 2 bank 3 线下便利店
    const [bankCard, setBankCard] = useState(""); // 银行账户号
    const [bankName, setBankName] = useState(""); // 银行名称
    const [bankText, setBankText] = useState(""); // 银行卡文案
    const [actualAmount, setActualAmount] = useState(0); //到账金额
    const [orderNo, setOrderNo] = useState(""); // 订单号
    const [isAgreeShow, setIsAgreeShow] = useState(false); // 是否显示同意借款协议
    const [isArrowShow, setIsArrowShow] = useState(false); // 是否显示跳转绑卡列表页

    const [isShelf, setIsShelf] = useState(0); // 是否是假产品 0假1真
    
    const [isBoxBank, setIsBoxBank] = useState(0); // 是否弹框 1弹 0不弹
    const [loanFailedTxt, setLoanFailedTxt] = useState(""); // 弹框里的文字
    const [loanFailedTitle, setLoanFailedTitle] = useState(""); // 弹框里的标题
    const [loanFailedType, setLoanFailedType] = useState(null); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡

    const [isConfirmLoan, setIsConfirmLoan] = useState(0); // 展示自动确认用款倒计时
    const [confirmLoanAutoTime, setConfirmLoanAutoTime] = useState(0); // 自动倒计时时间
    const [confirmAutoTimes, setConfirmAutoTimes] = useState("00:00:00"); // 展示的自动倒计时时间

    const [isShowAgreementModal, setIsShowAgreementModal] = useState(false); // 是否显示协议的弹框

    useEffect(() => {
        setTitle("Loan Information");
        setNavExpansion(0); // 是否隐藏头部
        setNavColor("#2D2D2D", "#F6F6F6");

        getLoanData();
        
        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    // 获取确认用款详情
    const getLoanData = async () => {
        let data = await loanDetailConfirmService();
        setAmount(data.islandfrom || 0);
        setPeriods(data.syour);
        setTermUnit(data.begged);
        setAmountTxt(data.buthonestly || "");
        // setAmountShow(data.amount_show || "");
        setDetails(data.ladder || []);
        setRepayPlans(data.ortaken || []);

        setLoanTerms(data.ladder || [])

        setNote(data.exclamation || "");
        setFirstPayTime(data.satisfied);
        setFirstPeriodDay(data.dressing);
        setProductLogo(data.doorway || "");
        setProductName(data.waspulled || "");
        setCardType(data.expedition);
        setBankCard(data.imean || "");
        setBankName(data.chimney || "");
        setBankText(data.someonehalf || "");
        setActualAmount(data.whispering || "");
        setOrderNo(data.professorhayling || "");
        setIsAgreeShow(data.stream || 0);
        setIsArrowShow(data.knowi || 0);

        setIsShelf(data.enoughgeorge || 0);

        setIsBoxBank(data.oneclimbing || 0);
        setLoanFailedTxt(data.hecan || "");
        setLoanFailedTitle(data.struggle || "");
        setLoanFailedType(data.thatwhen || "");

        setIsConfirmLoan(data.breath || 0);
        let auto_time = data.nightmare || 0;
        setConfirmLoanAutoTime(data.nightmare || 0);
        timerInterval(auto_time);
    }

    // 改变借款协议选中状态
    const changeAgreementCheckStatus = () => {
        setIsChekedAgreement(!isChekedAgreement);
    }
    // 跳转合同详情
    const goAgreement = () => {
        window.location.href = `https://summersetlending.com/wokrd/soleVa`;
        // props.history.push("/ptka-sea");
        // navigate("/wokrd/soleVa");
    }
    // 跳转换绑卡列表
    const changeAccount = () => {
        // props.history.push(`/ptka-carm?offinding=${orderNo}&havesulked=${params.havesulked}&${paramsDetail}`);
        // navigate(`/ptka-carm?offinding=${orderNo}&havesulked=${params.havesulked}&${paramsDetail}`);
        window.location.href = `/ptka-carm?offinding=${orderNo}&havesulked=${params.havesulked}&${paramsDetail}`;
    }
    // 关闭状态弹框
    const closeErrorModal = () => {
        setIsBoxBank(0);
    }
    // 去借款
    const goLoan = async () => {
        if (!isChekedAgreement) {
            ToastType("Toast", "Please check the note first");
            return;
        }
        let startTimeD = new Date();
        let startTime = `${parseInt(startTimeD.getTime() / 1000)}`;
        let data = await loanDetailPushService({
            amount: amount, // 借款金额
            periods: periods, // 期限
            termUnit: termUnit, // 期限类型
        });
        uploadRiskLoan(params.havesulked, startTime);
        data.household && window.location.replace(`${data.household}&${paramsDetail}`); // 跳转到订单详情页
        // data.household && props.history.replace(`${data.household}&${paramsDetail}`); // 跳转到订单详情页
        // data.household && navigate(`${data.household}&${paramsDetail}`, {replace: true}); // 跳转到订单详情页
    }
    // 根据是否显示协议而进行弹框还是跳转
    const confirmFunc = () => {
        if(isAgreeShow) {
            setIsShowAgreementModal(true);
            return;
        } else {
            goLoan();
        }
    }
    // 倒计时
    // 倒计时转换
    const setCountDown = (time) => {
        let count = time;
        if(count > 0) {
            let hour = Math.floor(count / 3600);
            let minute = Math.floor((count / 60) % 60);
            let second = Math.floor(count % 60);
            let h = hour >= 10 ? hour : `0${hour}`;
            let m = minute >= 10 ? minute : `0${minute}`;
            let s = second >= 10 ? second : `0${second}`;
            setConfirmAutoTimes(`${h}:${m}:${s}`);
        }
    }
    const timerInterval = (auto_time) => {
        let time = auto_time;
        timer = setInterval(() => {
            if(time > 0) {
                time -= 1;
                setConfirmLoanAutoTime(time);
                setCountDown(time);
            } else {
                clearInterval(timer);
                // goLoan(); // 时间到直接去借款
            }
        }, 1000);
    }
    // 取消自动确认
    const cancelAutoConfirm = async () => {
        let data = await autoConfirmButtonService({
            orderNo: orderNo,
        });
        getLoanData();
    }

    return (
        <div className="P-confirmOfLoan">
            {/* <SafeArea /> */}
            {/* 顶部借款金额 */}
            <div className="P-confirmOfLoan_amount">
                <p className="amount_title">Loan Amount</p>
                <div className="amount">
                    <p>{amountTxt || "₱ 0"}</p>
                    <img src={amount_icon} className="amount_icon" />
                </div>
                {/*{isShelf == 1 ? <img src={shelfImg} className="shelfImg" /> : null}*/}
                {/* 倒计时后自动确认 */}
                {/* {isConfirmLoan ? <div className="amount_countdown">
                    <div className="countdown_tips">
                        <img src={confirm_countdownIcon} className="confirm_countdownIcon" />
                        <p>Submit Automatically After <span>{confirmAutoTimes || ""}</span></p>
                    </div>
                    <p className="countdown_cancel" onClick={() => cancelAutoConfirm()}>Cancel</p>
                </div> : null} */}

                <div className="loan-detail">

                    <div>
                        <p className="load-detail-p"><img className="time-icon" src={TimeIcon}></img>Loan Term</p>
                        <p className="load-detail-second-p">{periods}</p>
                    </div>
                    <div>
                        <p className="load-detail-p"><img className="date-icon" src={DateIcon}></img>First Repayment</p>
                        <p className="load-detail-second-p">{firstPayTime}</p>
                    </div>

                </div>

            </div>
            {/* 借款信息区域 */}
            <div className="P-title">Receiving account</div>
            <div className="P-confirmOfLoan_infoArea">
                {/*<p className="title_note">{note || "If the first installment is repaid on time, the second installment will be waived."}</p>*/}
                {/* 银行卡信息区域 */}
                <div className="infoArea_bank">
                    <div className="bank_info">
                        <p className="name">{bankName || "Name of Bank"}</p>
                        <div className="card">
                            {loanFailedType == 0 ? <img src={bankError_confirm_normalIcon} className="error_tipsIcon" /> : loanFailedType == 1 || loanFailedType == 7 ? <img src={bankError_confirm_limitIcon} className="error_tipsIcon" /> : loanFailedType == 2 || loanFailedType == 99 ? <img src={bankError_confirm_errorIcon} className="error_tipsIcon" /> : null}
                            <p className="card_num">{bankCard || "-"}</p>
                        </div>
                    </div>
                    {isArrowShow ? <img src={confirm_goBank} onClick={() => changeAccount()} className="confirm_goBank" /> : null}
                </div>

                {/* 贷款区域 */}
                <div className="loan-terms">
                    <div className="loan-terms-title">Loan Details</div>
                    <ul>
                        {loanTerms?.map((term => {
                            return (
                                <li className="loan-term">
                                    <div className="loan-term-left">{term['charts']}</div>
                                    <div className="loan-term-right">{term['scribble']}</div>
                                </li>
                            )
                        }))}

                    </ul>

                </div>

                {/* 还款计划信息区域 */}

                <div className="payment-plans">
                    <div className="payment-plan-title">
                        <div className="payment-plan-title-txt">Repayment Plan</div>
                        <img src={payment_icon} alt="" className="payment-icon"/>
                    </div>
                    <ul>
                        {repay_plans?.map((plan, index) => {
                            return (
                                <div className="payment-plan-item-wrapper">
                                    <li className={index === 0 ? "payment-plan-item active" : "payment-plan-item"}>
                                        <div>
                                            <span>{index + 1} Term</span>
                                            <span>Repayment Date</span>
                                        </div>
                                        <div>
                                            <span>{plan.islandfrom}</span>
                                            <span>{plan.idaren}</span>
                                        </div>

                                    </li>
                                    {index === 0 && <p className="note">{note}</p>}
                                </div>

                            )
                        })}

                    </ul>

                </div>

                {/*/!* 还款计划信息区域 *!/*/}
                {/*{repay_plans && repay_plans.length > 0 && (*/}
                {/*    <div className="infoArea_repay">*/}
                {/*        <div className="repay_title">*/}
                {/*            <div className="title_time">*/}
                {/*                <p>Initial Repayment</p>*/}
                {/*                <p>{firstPayTime || "-"}</p>*/}
                {/*        </div>*/}
                {/*   </div>*/}
                {/*    <ul className="repay_info">*/}
                {/*        <p className="repay_info_title">Repayment Plan</p>*/}
                {/*        {repay_plans.map((item, index) => {*/}
                {/*            return (*/}
                {/*                <li key={"conf_repayI" + index}>*/}
                {/*                    <div className="info ellipsis">*/}
                {/*                        <div className="info_amount">*/}
                {/*                            <p>{item.idaren || ""}</p>*/}
                {/*                            <p>{item.islandfrom || ""}</p>*/}
                {/*                        </div>*/}
                {/*                        <div className="info_date">*/}
                {/*                            <p>{item.forced || ""}</p>*/}
                {/*                            <p>{item.fight || ""}</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*            )*/}
                {/*        })}*/}
                {/*    </ul>*/}
                {/*</div>)}*/}
                {/* 产品信息 */}

                <div className="footer">
                    <img src={ThatAll} alt=""/>
                </div>

                {/*<div className="infoArea_product">*/}
                {/*    <p>Product Name</p>*/}
                {/*    <div className="product">*/}
                {/*        {productLogo ? <img src={productLogo} className="productLogo" /> : null}*/}
                {/*        <p>{productName || "-"}</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {/* 固定底部区域 */}
            <div className="P-confirmOfLoan_bottom">
                {/* 借款协议 */}
                {isAgreeShow ?
                    <div className="bottom_agreement">
                        <img src={isChekedAgreement ? card_checked : card_notChecked}
                             onClick={() => changeAgreementCheckStatus()}/>
                        <p>I affirm my acceptance of <span onClick={() => goAgreement()}>loan agreement</span></p>
                    </div> : null}
                {/* 按钮 */}
                <div className="bottom_btnArea">

                    <Button
                        content="Confirm"
                        clickFunc={() => goLoan()}/>
                </div>
            </div>
            {/* 银行卡错误提示弹框 */}
            {isBoxBank ? (
                <ErrorModal
                loanFailedType={loanFailedType}
                loanFailedTitle={loanFailedTitle || ""}
                loanFailedTxt={loanFailedTxt || ""}
                btnArray={[{
                    content: "Replace",
                    clickFunc: () => changeAccount(),
                    btnStyle: {background: "#F5F8F6", color: "#2D2D2D", marginBottom: "10px", border: "2px solid #2D2D2D"}
                }, {
                    content: "Continue",
                    clickFunc: () => closeErrorModal(),
                    btnStyle: {background: "#2D2D2D", color: "#FAFAFA", border: "2px solid #2D2D2D"}
                }]}>
                    {loanFailedType != 0 ? (
                        <div className="p-confirmOfLoan_errorBank" style={{background: loanFailedType == 1 || loanFailedType == 7 ? "#FEF6E5" : "#FFE3E2", color: loanFailedType == 1 || loanFailedType == 7 ? "#F8AD1E" : "#B12524"}}>
                            <p className="bankName">{bankName || "Receiving Account"}</p>
                            <div className="errorBank_info">
                                {loanFailedType == 1 || loanFailedType == 7 ? <img src={bankError_confirm_limitIcon} className="error_tipsIcon" /> : loanFailedType == 2 || loanFailedType == 99 ? <img src={bankError_confirm_errorIcon} className="error_tipsIcon" /> : null}
                                <p className="bankNum">{bankCard || "-"}</p>
                            </div>
                        </div>
                    ) : null}
                </ErrorModal>
            ) : null}
            {/* 借款协议弹框 此包不用 */}
            {isShowAgreementModal ? 
            <Modal
            titleContent="Loan agreement"
            close={() => setIsShowAgreementModal(false)}>
                <div className="agreementArea">
                    <div className="agreementArea_agreement">
                        <LoanAgreementCom />
                    </div>
                    <div className="agreementArea_btn">
                        <Button
                        content="I Understand"
                        clickFunc={() => goLoan()} />
                    </div>
                </div>
            </Modal> : null}
        </div>
    )
}

export default ConfirmOfLoan;

