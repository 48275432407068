import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';
import { userCardListService, changeOrderAccountService } from "../../../axios/service";
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion, closeSyn, setNavColor } from "../../../common/js/navigateTools";
import "./index.less";
import card_add from "../../../common/images/bindCard/card_add.png";
import bankError_card_limitIcon from "../../../common/images/bankError/bankError_confirm_limitIcon.png";
import bankError_card_errorIcon from "../../../common/images/bankError/bankError_confirm_errorIcon.png";

const BindCardList = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `realize=${params.realize}&notch=${params.notch}`;

    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [choosedBank, setChoosedBank] = useState({});
    const [bankList, setBankList] = useState([]); // 银行卡列表
    const [ewalletList, setEwalletList] = useState([]); // 电子钱包列表
    const [choosedBanner, setChoosedBanner] = useState(1); // 选择card类型 bank e-wallet 点进来默认E-wallet: 1
    const [choosedCard, setChoosedCard] = useState([]); // 选中card类型的列表

    useEffect(() => {
        setTitle("Bank Card");
        setNavExpansion(0); // 是否隐藏导航栏
        setNavColor("#D2D2D2", "#F5F8F6");
        
        getBaseInfo();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const getBaseInfo = async () => {
        let data = await userCardListService();
        setList(data.rowacross);
        /*let bankList = []; let ewalletList = [];
        this.state.list.map(item => {
            if(item.deferring == "Bank") {
                bankList = item.impressing;
            } else if(item.deferring == "E-wallet") {
                ewalletList = item.impressing;
            }
            item.impressing && item.impressing.map(item1 => {
                if(item1.hapless == 1) {
                    this.setState({
                        choosedBank: item1, // 当前选中的卡
                    })
                }
            })
        });
        this.setState({
            bankList: bankList,
            ewalletList: ewalletList
        });*/
    }
    // 跳转到bindCard添加绑卡页面
    const jumpBindCard = (type) => {
        let choosed_banner = type; // 选中的卡类型 1E-wallet 2Bank
        setChoosedBanner(choosed_banner);
        // navigate(`/ptka-num?offinding=${params.offinding}&havesulked=${params.havesulked}&choosedCardType=${choosed_banner}&${paramsDetail}`);
        window.location.href = `/ptka-num?offinding=${params.offinding}&havesulked=${params.havesulked}&choosedCardType=${choosed_banner}&${paramsDetail}`;
    }
    // 确认更换银行卡/e-wallet
    const confirmBtn = async (choosedItem) => {
        let data = await changeOrderAccountService({
            bindId: choosedItem.places || "",
        });
        getBaseInfo();
        if (params.reFrom == '1') { // reFrom
            closeSyn(); // 关闭当前H5
        } else {
            // window.history.back();
            // window.history.go(-1);
            // window.location.reload();//强行刷新(返回上一页刷新页面)
            navigate(-1);
        }
    }
    // 选择银行卡/ewallet
    const chooseMainBank = (item) => {
        // console.log(item);
        setChoosedBank(item);
        confirmBtn(item);
    }
    // 换bank和E-wallet banner以及其列表 (不需要切换的就不使用)
    const changeCardType = (item) => {
        // console.log(item);
        let choosed_banner = item.expedition;
        setChoosedBanner(choosed_banner);
        let choosed_card = item.expedition == 2 ? bankList : ewalletList;
        setChoosedCard(choosed_card);
    }

    return (
        <div className="P-bindCardList">
            {/* 银行卡列表 */}
            <div className="P-bindCardList_list">
            {list && list.length > 0 && list.map((item, index) => {
                return (
                    <div className="list" key={"cardLi" + index}>
                        {/* <div className="list_type">
                            <p>{item.wouldthink || ""}</p>
                        </div> */}
                        <ul className="list_s">
                            {item.bowed && item.bowed.length > 0 && item.bowed.map((child, i) => {
                                return (
                                    <li className={child.finds == 1 ? "s_info chosedList" : "s_info"} key={"cardLii" + i} onClick={() => chooseMainBank(child)}>
                                        <div className="s_info_txt">
                                            <div className={child.finds ? "txt" : "txt left"}>
                                                <div className="name">
                                                    {child.thati ? <img src={child.thati} className="card_logo" /> : null}
                                                    <p>{child.chimney || ""}</p>
                                                </div>
                                                <p className="type">{item.wouldthink || ""}</p>
                                                <p className="num">{child.veplanned || ""}</p>
                                            </div>
                                            {/* {child.finds ? 
                                            <div className="choosed">
                                                <img src={card_checked} />
                                            </div> : null} */}
                                        </div>
                                        {child.thatwhen != 0 ? (
                                            <div className={child.thatwhen == 1 || child.thatwhen == 7 ? "limit_card" : child.thatwhen == 2 || child.thatwhen == 99 ? "error_card" : ""}>
                                                {child.thatwhen == 1 || child.thatwhen == 7 ? <img src={bankError_card_limitIcon} /> : child.thatwhen == 2 || child.thatwhen == 99 ? <img src={bankError_card_errorIcon} /> : null}
                                                <p>{child.hecan || ""}</p>
                                            </div>
                                        ) : null}
                                    </li>
                                )
                            })}
                            <li className="s_add" onClick={() => jumpBindCard(item.expedition)}>
                                <img src={card_add} className="card_add" />
                                <p>Add a new {item.expedition == 1 ? "E-wallet" : "Bank Card"}</p>
                            </li>
                        </ul>
                    </div>
                )
            })}
            </div>
        </div>
    )
}

export default BindCardList;