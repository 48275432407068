import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion, setNavColor } from '../../../common/js/navigateTools';
import { cardInitService, bindCardNewService } from "../../../axios/service";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import "./index.less";
import chooseArrow from '../../../common/images/bindCard/chooseArrow.png';

const BindCard = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));

    const cardTypeList = [{
        type: "E-wallet",
        cardId: 1
    }, {
        type: "Bank",
        cardId: 2
    }];

    const [eWalletList, setEwalletList] = useState([]); // 电子钱包详情
    const [bankList, setBankList] = useState([]); // 银行卡详情
    const [choosedCardTypeList, setChoosedCardTypeList] = useState([]); // 需要选择的银行卡/e-wallet的类别的列表

    const [bankCardItemList, setBankCardItemList] = useState([]); // bankCardList
    const [ewalletCardItemList, setEwalletCardItemList] = useState([]); // 电子钱包的列表

    const [isShowCardTypeModal, setIsShowCardTypeModal] = useState(false);// 是否显示card卡类型的弹框
    const [choosedCardType, setChoosedCardType] = useState(params.choosedCardType || ""); // 选择的卡的类型 - 默认params携带过来的选择的卡的类型 可以通过Select进行切换
    
    const [isShowCardItemModal, setIsShowCardItemModal] = useState(false); // 是否显示选择卡的item的弹框 选择要添加的卡的类别
    const [bindCardItemList, setBindCardItemList] = useState([]); // 银行卡的列表
    const [bindCardItemName, setBindCardItemName] = useState(""); // 要选择的卡的key
    const [bankCardItemName, setBankCardItemName] = useState(""); // 银行卡的key
    const [ewalletCardItemName, setEwalletCardItemName] = useState(""); // 电子钱包的key
    const [choosedBindCardItem, setChoosedBindCardItem] = useState({}); // 被选中的卡类别的数据

    const [codeDic, setCodeDic] = useState({}); // 存放输入的绑卡的数据

    useEffect(() => {
        setTitle("Add Bind Card");
        setNavExpansion(0); // 是否隐藏导航栏
        setNavColor("#D2D2D2", "#FFFFFF");

        const getBaseInfo = async () => {
            let data = await cardInitService();
            var curr_ewalletList = [], curr_bankList = [];
            var curr_ewalletCardItemList = [], curr_bankCardItemList = [];
            var curr_ewalletCardItemName = "", curr_bankCardItemName = "";
            data.closely && data.closely.map(item => {
                if(item.courteously == 1) { // E-wallet
                    curr_ewalletList = item.closely;
                    setEwalletList(curr_ewalletList);
                    item.closely.map(child => {
                        if(child.covered == "what2") { // what1选择框 what2输入内容
                            let ecodeDic = codeDic; // 需要存入的key-value对象
                            ecodeDic[child.georgecould] = child.theflower || ""; // 初始化时key[对应的若有默认value信息则存入默认value信息
                            setCodeDic(ecodeDic);
                        } else if(child.covered == "what1") { // what1选择框 what2输入内容
                            curr_ewalletCardItemList = child.exclamation || [];
                            setEwalletCardItemList(curr_ewalletCardItemList); // E-wallet的选择框的数组内容
                            curr_ewalletCardItemName = child.georgecould || "";
                            setEwalletCardItemName(curr_ewalletCardItemName); // E-wallet的选择框的key
                        }
                    })
                } else if(item.courteously == 2) { // Bank
                    curr_bankList = item.closely;
                    setBankList(curr_bankList);
                    item.closely.map(child => {
                        if(child.covered == "what2") { // what1选择框 what2输入内容
                            let bcodeDic = codeDic; // 需要存入的key-value对象
                            bcodeDic[child.georgecould] = child.theflower || ""; // 初始化时key[对应的若有默认value信息则存入默认value信息]
                            setCodeDic(bcodeDic);
                        } else if(child.covered == "what1") { // what1选择框 what2输入内容
                            curr_bankCardItemList = child.exclamation || [];
                            setBankCardItemList(curr_bankCardItemList); // 银行卡的选择框的数组内容
                            curr_bankCardItemName = child.georgecould || "";
                            setBankCardItemName(curr_bankCardItemName); // 银行卡的选择框的key
                        }
                    })
                }
                let curr_choosedCardTypeList = choosedCardType && choosedCardType == 1 ? curr_ewalletList : curr_bankList; // 绑卡信息列表 Bank/E-wallet
                setChoosedCardTypeList(curr_choosedCardTypeList);
                let curr_bindCardItemList = choosedCardType && choosedCardType == 1 ? curr_ewalletCardItemList : curr_bankCardItemList; // 要选择的选择框的卡的列表
                setBindCardItemList(curr_bindCardItemList);
                let curr_bindCardItemName = choosedCardType && choosedCardType == 1 ? curr_ewalletCardItemName : curr_bankCardItemName; // 要选择的卡的选择框的key
                setBindCardItemName(curr_bindCardItemName);
            })
        }
        getBaseInfo();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // 添加新的银行卡/ewallet
    const addNewBank = async () => {
        let paramDic = {
            expedition: choosedCardType, // 1.ewallet 2 bank
            [bindCardItemName]: choosedBindCardItem.courteously, // 选中的卡的id
            butcould: "FGSADA42643432" //混淆字段
        }
        Object.keys(codeDic).map((key, index) => {
          if(key !== bindCardItemName) {
            paramDic[key] = codeDic[key];
          }
        });
        if (choosedCardType == 1) {
            eWalletList.map((item) => {
                if (item.covered == 'what2') { // 输入框
                    if (!Object.keys(codeDic).includes(item.georgecould)) {
                        paramDic[item.georgecould] = item.theflower; // 没有输入的话采用theflower - displayValue
                    }
                }
            })
        }
        console.log(codeDic ,paramDic);
        // 确认
        let data = await bindCardNewService(paramDic);
        window.history.back();
    }
    // 修改当前选择的卡类型 - 切换对应的类型的列表
    const changeChoosedCardTypeList = (item) => {
        let now_choosedCardType = item.cardId;
        setChoosedCardType(now_choosedCardType);
        let now_choosedCardTypeList = item.cardId && item.cardId == 1 ? eWalletList : bankList;
        setChoosedCardTypeList(now_choosedCardTypeList);
        let now_bindCardItemList = item.cardId == 1 ? ewalletCardItemList : bankCardItemList;
        setBindCardItemList(now_bindCardItemList);
        let now_bindCardItemName = item.cardId == 1 ? ewalletCardItemName : bankCardItemName;
        setBindCardItemName(now_bindCardItemName);


        setIsShowCardTypeModal(false);
        setCodeDic({}); // 将传入的key清除
        setChoosedBindCardItem({}); // 将选中的银行卡清除

        now_choosedCardTypeList.map(child => {
            if(child.covered == "what2") { // what1选择框 what2输入内容
                let ccodeDic = codeDic; // 需要存入的key-value对象
                ccodeDic[child.georgecould] = child.theflower || ""; // 初始化时key[对应的若有默认value信息则存入默认value信息
                ccodeDic[now_bindCardItemName] = ""; // 清空选择的卡名
                setCodeDic(ccodeDic);
            }
        })
    }
    // 选择要添加的银行卡/e-wallet
    const changeChoosedBindCardItem = (item) => {
        let codeDicI = codeDic;
        codeDicI[bindCardItemName] = item.knowthe;
        setChoosedBindCardItem(item); // 被选中的卡类别的数据
        setIsShowCardItemModal(false); // 是否显示选择卡的item的弹框 选择要添加的卡的类别
        setCodeDic(codeDicI); // 将卡名传给key
    }
    // 获取当前input中的内容 将内容推入到对应的key中
    const inputChange = (code, event) => {
        let codeDicN = codeDic;
        codeDicN[code] = event.target.value;
        setCodeDic(codeDicN);
    }
    // input显示的value
    const valueBind = (code) => {
        return Object.keys(codeDic).includes(code) ? codeDic[code] : ""; // 返回对应的key的内容 value显示
    }
    // 展示弹出选择银行卡弹框
    const showItemModal = () => {
        setIsShowCardItemModal(true);
    }
    // 隐藏选择银行卡弹框
    const closeItemModal = () => {
        setIsShowCardItemModal(false);
    }

    return (
        <div className="P-bindCard">
            {/* 绑定新卡信息 */}
            <div className="P-bindCard_inputArea">
                {/* 绑定新卡input内容 */}
                <div className="inputArea">
                    <div className="scrollArea">
                        {/* 类型选择 */}
                        <div className="inputArea_typeList">
                        {cardTypeList.map((item, index) => {
                            return (
                                <div className={choosedCardType == item.cardId ? "type choosed_type" : "type"} onClick={() => changeChoosedCardTypeList(item)} key={"cardType" + index}>
                                    <p>{item.type || ""}</p>
                                </div>
                            )
                        })}
                        </div>
                        {/* input输入列表 */}
                        <div className='inputArea_inputList'>
                            {choosedCardTypeList.length > 0 && choosedCardTypeList.map((item, index) => {
                                return (
                                    <div className='inputList' key={"cardInputList" + index}>
                                        <span className='inputList_title'>{item.queer || ""}</span>
                                        <div className="inputList_input">
                                            <div className={item.covered == "what1" && Object.keys(choosedBindCardItem).length !== 0 ? 'inputList_input_inputchoosedbg' : 'inputList_input_con'}>
                                                {item.covered == "what1" && choosedCardType == 1 &&
                                                    choosedBindCardItem && choosedBindCardItem.thati ? 
                                                    (<img src={choosedBindCardItem.thati} className='inputList_input_itemImage' />) : 
                                                    (item.covered == "what1" && choosedCardType == 1 && <span className='inputList_input_itemImage_none'></span>)
                                                }
                                                <input
                                                    placeholder={item.modestly}
                                                    // disabled={item.covered == "what1" ? true : false} // ee选择框 rr输入框
                                                    readOnly={item.covered == "what1" ? true : false} // ee选择框 rr输入框
                                                    type={item.someof == 1 ? "number" : "text"}
                                                    pattern={item.someof == 1 ? "[0-9]*" : ""} // 1数字
                                                    inputMode={item.someof == 1 ? "numeric" : "text"} // 是否唤起数字键盘/文字键盘
                                                    onChange={event => inputChange(item.georgecould, event)}
                                                    defaultValue={valueBind(item.georgecould)}
                                                    className={item.covered == "what1" && choosedCardType == 1 ? "input_choose_area" : ""}
                                                    id='cardInput'
                                                    key={item.georgecould + "-i" + choosedCardType}
                                                />
                                                {item.covered == "what1" && <div className='input_click' onClick={() => showItemModal()}></div>}
                                            </div>
                                            {item.covered == "what1" ? <img
                                            onClick={() => item.covered == "what1" && showItemModal()} 
                                            src={ chooseArrow}
                                            className='inputList_input_selectIcon' /> : null}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {/* 按钮提交 */}
                        <div className="inputArea_button">
                            <Button
                            content="Confirm"
                            clickFunc={() => addNewBank()} />
                        </div>
                    </div>
                </div>
            </div>
            {/* 选择要添加的卡 的 弹框 */}
            {isShowCardItemModal ? (
                <Modal
                isBottom
                titleContent={choosedCardType == 1 ? "E-wallet" : "Bank"}
                close={() => closeItemModal()}>
                    <ul className='cardModal'>
                        {bindCardItemList && bindCardItemList.map((item, index) => {
                          return (
                            <li 
                            key={"bindCardItem" + index} 
                            className={choosedBindCardItem && choosedBindCardItem.courteously == item.courteously ? 'cardModal_cardItemList chosedItem' : 'cardModal_cardItemList'}
                            onClick={() => changeChoosedBindCardItem(item)}
                            >
                              <div className='item_info'>
                                {item.thati && (<img src={item.thati || ""} className='item_icon' />)}
                                <div className={choosedBindCardItem && choosedBindCardItem.courteously == item.courteously ? "choosedItemColor" : "normalItemColor"}>
                                    <p>{item.knowthe || ""}</p>
                                </div>
                              </div>
                              {/* {choosedBindCardItem && choosedBindCardItem.courteously == item.courteously ? <img src={card_checked} className='item_choosed' /> : <img src={card_notChecked} className="item_notChoosed" />} */}
                            </li>
                          )
                        })}
                    </ul>
                </Modal>
            ) : null}
        </div>
    )
}

export default BindCard;